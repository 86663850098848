import { BrowserRouter, Routes, Route } from "react-router-dom";
import { PostProvider } from "./contexts/PostContext";
import Todopage from "./pages/todolist/Todopage";
import Addtodo from "./pages/addtodo/Addtodo";
import Navbar from "./components/navbar/Navbar";

function App() {
  return (
    <div className="layouts">
      <BrowserRouter>
        <PostProvider >
          <Navbar />
          <Routes >
            <Route path="/" element={<Todopage />} />
            <Route path="/add" element={<Addtodo />} />
          </Routes>
        </PostProvider>
      </BrowserRouter>
    </div>
  );
}

export default App;
