import { createContext, useState, useEffect } from "react";

export const PostContext = createContext()

export const PostProvider = ({ children }) => {
  const [editable, setEditable] = useState({})
  const [isEditing, setIsediting] = useState(false)
  const [todos, setTodos] = useState(
    JSON.parse(window.localStorage.getItem('todo')) || [])


  useEffect(() => {
    window.localStorage.setItem('todo', JSON.stringify(todos))
  }, [todos])


  const addtodo = (todo) => {
    setTodos(prev => [...prev, todo])
  }

  const updatetodo = (todo) => {
    setTodos(todos.map((item) => {
      if (item.id === todo.id) {
        let dt = new Date().toLocaleDateString()
        let tm = new Date().toLocaleTimeString()
        return { ...todo, date: dt, time: tm }
      }
      return item
    }))
    cleanEditable()
    setIsediting(false)
  }

  const completed = (ids) => {
    setTodos(prev => prev.map((todo) => {
      if (todo.id === ids) {
        return { ...todo, complete: !todo.complete }
      }
      return todo;
    }))
  }

  const cleanEditable = () => {
    setEditable({})
  }
  const edittodo = (ids) => {
    setEditable(todos.find(item => item.id === ids))
  }
  const deletetodo = (ids) => {
    setTodos(prev => prev.filter((todo) => todo.id !== ids)
    )

  }
  const removeall = () => {
    setTodos([])
  }


  return (
    <PostContext.Provider value={{ todos, addtodo, deletetodo, completed, cleanEditable, editable, edittodo, updatetodo, isEditing, setIsediting, removeall }}>

      {children}

    </PostContext.Provider>

  )
}

export default PostContext;


