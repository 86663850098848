
import React, { useContext, useState } from 'react'
import { useNavigate } from 'react-router-dom'
import PostContext from '../../contexts/PostContext'
import './addtodo.css'


const Addtodo = () => {
  const { addtodo, editable, updatetodo, isEditing } = useContext(PostContext)
  const [task, setTask] = useState(editable)
  const navigator = useNavigate()

  const changeHandler = (e) => {
    e.preventDefault()
    setTask(prev => {
      return { ...prev, [e.target.name]: e.target.value }
    })
  }
  const handleSubmit = (e) => {
    e.preventDefault()

    if (!isEditing) {
      let dt = new Date().toLocaleDateString()
      let tm = new Date().toLocaleTimeString()
      addtodo({ ...task, id: Date.now(), complete: false, date: dt, time: tm })
    } else {
      updatetodo(task)
    }
    setTask({})
    navigator("/")
  }

  return (
    <section className='addtodos'>
      <div className="add-container">
        <h1>add to task</h1>
        <hr />
        <form className='inputform' onSubmit={(e) => handleSubmit(e)}>
          <input type="text" name='title' value={task.title || ''} placeholder='Title of your task' onChange={changeHandler} autoFocus required />
          <input type="text" name='detail' value={task.detail || ''} required placeholder='detail of your task'
            onChange={changeHandler} />
          <button type='submit' className="addtbn">{isEditing ? 'Update Task' : 'Add Task'} </button>

        </form>

      </div>

    </section>
  )
}

export default Addtodo
