import { Link } from 'react-router-dom'
import './navbar.css'

const Navbar = () => {
  return (
    <div className='nav-bar'>
      <Link className="logo" to="/">
        <h1>LOGO</h1>
      </Link>
      <div className="menu">
        <Link to="/" className='menu-item'>Task List</Link>
        <Link to="/add" className='menu-item'>Add Task</Link>

      </div>
    </div>
  )
}

export default Navbar;
