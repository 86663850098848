import { useContext } from 'react'
import { useNavigate, Link } from 'react-router-dom'
import { FiEdit, FiTrash2, FiCheckSquare } from 'react-icons/fi';
import PostContext from '../../contexts/PostContext'
import './todo.css'

export default function Todopage() {
  const navigate = useNavigate()
  const { todos, deletetodo, completed, edittodo, setIsediting, removeall } = useContext(PostContext)

  const edittodos = (ids) => {
    edittodo(ids)
    setIsediting(true)
    navigate("/add")
  }
  const deleteHandler = (todoid) => {
    if (window.confirm('\t  Are you Sure, \n  you want to delete ?')) {
      deletetodo(todoid)
    }
  }
  const clearAll = () => {
    if (window.confirm('\t  Are you Sure, \n  you want to delete All ?')) {
      removeall()
    }

  }


  return (
    <section >
      <div className="todolist">

        <h2 className="main-title">Today's Task</h2>
        <Link to="/add" className="add-tasks">Add Task</Link>
        <hr />
        {todos?.map((todo) => {
          return (
            <div className="todo-list" key={todo.id}>

              <div className={todo.complete ? "todotext done" : "todotext"} >
                <div className="todo-content">
                  <h2 className={todo.complete ? 'stricks' : ""}>{todo.title} </h2>
                  <p className={todo.complete ? 'stricks' : ""}>{todo.detail} </p>
                </div>
                <div className="date-time">
                  <p className='date'>{todo.date} </p>
                  <p className='time'>{todo.time} </p>
                </div>
              </div>
              <div className="buttons">
                <FiCheckSquare className={todo.complete ? 'icon check greens' : 'icon check'} onClick={() => completed(todo.id)} />
                <FiEdit className='icon edit' onClick={() => edittodos(todo.id)} />
                <FiTrash2 className='icon trash' onClick={() => deleteHandler(todo.id)} />
              </div>
            </div>
          )
        })}
        {todos.length > 1 && <button className="clear-tasks" onClick={clearAll}>Clear All</button>}
      </div>
    </section>
  )
}
